(function ($) {
  Drupal.behaviors.videoBlockV1 = {
    attach: function (context, settings) {
      var $modules = $('.js-video-block--v1');

      $modules.each(function () {
        var $module = $(this);

        $module.addClass('attached');
      });
    }
  };
})(jQuery);
